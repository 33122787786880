import React from "react";
import { useNavigate } from "react-router-dom";
import Progress from "../../components/UI/Progress";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { CheckCircle, DashCircle } from "react-bootstrap-icons";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import moment from "moment";
import Button from "../../components/UI/Button";

Chart.register(CategoryScale);

const doughnutChartOptions = {
  maintainAspectRatio: false,
  layout: {
    // padding: {
    //     top: 15,
    //     left: 15,
    //     right: 15,
    //     bottom: 15
    // },
    // align: "right"
  },
  responsive: true,
  cutout: "80%",
  color: "gray  ",
  animations: {
    tension: {
      duration: 1000,
      easing: "linear",
      from: 1,
      to: 0,
      loop: true,
    },
  },
  plugins: {
    title: {
      display: false,
      // text: "Budget usage",
      color: "gray  ",
      font: {
        size: 18,
      },
      align: "center",
      position: "center",
    },
    legend: {
      display: true,
      position: "right",

      textDirection: "rtl",
      labels: {
        font: {
          size: 13,
          weight: "500",
          family: "Inter",
        },
        usePointStyle: true,
        fontColor: "#000",
        pointStyle: "circle",
        boxWidth: 5,
        boxHeight: 5,
        // pointStyleWidth: 5
      },
    },
  },
};
const doughnutChartLabels = ["Equipment", "Materials", "Labor"];
const SelectedMilestone = ({ selectedMilestone, setDetailsShown }) => {
  const navigate = useNavigate();
  return (
    <>
      {/* <h1 className="text-start w-full text-lg font-medium">
      Milestone details
    </h1> */}
      <ul className="w-full text-xs space-y-3 p-2">
        <li className="flex flex-col justify-between">
          <h3 className="text-gray-700 text-sm font-semibold">
            {selectedMilestone?.name}
          </h3>
          <span className="text-xs mt-2 flex items-center text-dark-gray">
            <span
              className={`${
                selectedMilestone.currentStatus === "COMPLETE"
                  ? "text-green-500 bg-green-100"
                  : "text-dark-red bg-light-red"
              } rounded-full text-xs p-1 text-center inline-flex items-center`}>
              <AccessAlarmIcon className="!w-4 !h-4" />
            </span>
            <span className="ml-2 whitespace-break-spaces">
              <span
                className={`mr-1 font-semibold ${
                  selectedMilestone.currentStatus === "COMPLETE"
                    ? "text-green-500"
                    : "text-dark-red"
                }`}>
                Due {moment(selectedMilestone?.end).calendar()}
              </span>
              ({moment(selectedMilestone?.end).endOf("day").fromNow()})
            </span>
          </span>
        </li>
        <hr />
        <li className="flex justify-between">
          <span className="text-dark-gray">Start date</span>
          <span>
            <span className="text-gray-700 font-semibold mr-1">
              {moment(selectedMilestone?.start).format("L")}
            </span>
          </span>
        </li>
        <li className="flex justify-between">
          <span className="text-dark-gray">Expected end date</span>
          <span>
            <span className="text-gray-700 font-semibold mr-1">
              {moment(selectedMilestone?.end).format("L")}
            </span>
          </span>
        </li>
        <li className="flex justify-between">
          <span className="text-dark-gray">Budget spent</span>
          <span>
            <span className="text-gray-700 font-semibold mr-1">
              {selectedMilestone?.budgetSpent.toLocaleString()}
            </span>
          </span>
        </li>
        <li className="flex justify-between">
          <span className="text-dark-gray">Budget remaining</span>
          <span>
            <span className="text-gray-700 font-semibold mr-1">
              {(
                selectedMilestone?.budget - selectedMilestone?.budgetSpent
              ).toLocaleString()}
            </span>
          </span>
        </li>
        <li className="flex justify-between">
          <span className="text-dark-gray">Percentage completed</span>
          <span>
            <span className="text-gray-700 font-semibold mr-1">
              {selectedMilestone?.percentageCompleted}%
            </span>
          </span>
        </li>
        <li className="flex justify-between">
          <span className="text-dark-gray">Schedule</span>
          <span>
            <span className="text-gray-700 font-semibold mr-1">
              <span
                className={`${
                  selectedMilestone.currentStatus === "ON_TRACK" ||
                  selectedMilestone.currentStatus === "TO_DO"
                    ? "text-dark-orange bg-light-orange"
                    : selectedMilestone.currentStatus === "COMPLETE"
                    ? "text-green-500 bg-green-100"
                    : selectedMilestone.currentStatus === "ON_TRACK"
                    ? "text-primary bg-primary/20"
                    : selectedMilestone.currentStatus === "WARNING"
                    ? "text-dark-red bg-light-red"
                    : ""
                } font-medium rounded-xl text-xs py-0.5 px-2 text-center inline-flex items-center`}>
                {selectedMilestone.currentStatus === "ON_TRACK"
                  ? "On track"
                  : selectedMilestone.currentStatus === "TO_DO"
                  ? "To do"
                  : selectedMilestone.currentStatus === "COMPLETE"
                  ? "Complete"
                  : selectedMilestone.currentStatus === "WARNING"
                  ? "On warning"
                  : ""}
              </span>
            </span>
          </span>
        </li>
        <hr />
        <h4 className="text-gray-700 text-sm font-semibold">Checklist</h4>
        <li className="flex justify-between">
          {selectedMilestone?.checklists.length > 0 ? (
            <span className="text-gray-700 space-y-2 font-semibold mr-1">
              {selectedMilestone?.checklists?.map((checklistItem, index) => (
                <span
                  className={`flex items-center ${
                    checklistItem.isChecked ? "text-primary" : "text-dark-gray"
                  }`}
                  key={index}>
                  {checklistItem.isChecked ? <CheckCircle /> : <DashCircle />}
                  <span className="ml-2">{checklistItem.title}</span>
                </span>
              ))}
            </span>
          ) : (
            <p className="w-full text-center text-gray-500">
              No checklist found
            </p>
          )}
        </li>
        <hr />
        <h4 className="text-gray-700 text-sm font-semibold">Tasks</h4>
        <li className="">
          <span className="w-full flex flex-col space-y-2">
            {selectedMilestone?.quantifiable_tasks_edited?.map(
              (item, index) => (
                <span className="" key={index}>
                  <span className="text-gray-700">{item.name}</span>
                  <Progress className="w-full" progress={item?.progress} />
                </span>
              )
            )}
          </span>
        </li>
        <hr />
        <h4 className="text-gray-700 text-sm font-semibold">Budget usage</h4>
        <li className="flex justify-between">
          <Doughnut
            width={150}
            height={150}
            className="h-full"
            options={doughnutChartOptions}
            data={{
              labels: doughnutChartLabels,
              datasets: [
                {
                  label: "Budget usage",
                  data: [
                    selectedMilestone?.totalEquipmentBudgetSpent,
                    selectedMilestone?.totalMaterialBudgetSpent,
                    selectedMilestone?.totalLaborBudgetSpent,
                  ],
                  borderColor: ["#6050DC", "#FF6B45", "#FFAB05"],
                  backgroundColor: ["#6050DC", "#FF6B45", "#FFAB05"],
                },
              ],
            }}
          />
        </li>
        <p className="flex justify-end text-primary my-2">
          <button
            className="text-sm"
            onClick={() => {
              setDetailsShown(true);
            }}>
            View more details
          </button>
        </p>
        <Button
          className="w-full shadow-sm hover:shadow-lg !bg-green-100 hover:!bg-green-200 !text-green-500 focus:!ring-0"
          onClick={() => {
            navigate("/phases/new", {
              state: { editMode: true, milestone: selectedMilestone },
            });
          }}>
          Edit Milestone
        </Button>
      </ul>
    </>
  );
};

export default SelectedMilestone;
