import React, { useEffect, useState } from "react";
import { Bell, ChevronDown, PlusCircle } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "../UI/IconButton";
import { useProject } from "../../store/project-context";
import conLogo from "../../assets/images/con-logo.png";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SearchFromList from "../UI/SearchFromList";
import Notifications from "../UI/Notifications";
import Toast from "../UI/Toast";
import { useNotification } from "../../store/notification-context";
// import { checkRoles } from "../../functions/validation";
import Confirm from "../UI/Confirm";
import { useSignOut, useAuthUser } from "react-auth-kit";
import { LogOutIcon, User2Icon } from "lucide-react";
import { Shield, ShieldOutlined } from "@mui/icons-material";

const Nav = (props) => {
  const [projectSwitched, setProjectSwitched] = useState(null);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [error, setError] = useState(null);
  const [insideManagement, setInsideManagement] = useState(false);
  const auth = useAuthUser();
  const signOut = useSignOut();
  const {
    projects,
    currentProject,
    loading,
    error: projectsError,
    changeProject,
    company,
    companyLoading,
    clear,
  } = useProject();
  const user = auth();
  const navigate = useNavigate();
  // const addProjectClickHandler = () => {
  //   navigate("/projects", { state: { addProject: true } });
  // };
  const [isConfirmShown, setIsConfirmShown] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname.includes("users") ||
      location.pathname.includes("projects")
    ) {
      setInsideManagement(true);
    } else {
      setInsideManagement(false);
    }
  }, [location]);
  const {
    notifications,
    notificationsLoading,
    notificationsError,
    markAllAsReadLoading,
    markAllAsRead,
    markAsRead,
  } = useNotification();

  const markAllAsReadHandler = async () => {
    try {
      await markAllAsRead({
        variables: {
          user_id: user.id,
        },
      });
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };
  const onNotificationClick = async (id) => {
    try {
      await markAsRead({
        variables: {
          id,
        },
      });
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };
  const showAnimatation = () => {
    setProjectSwitched(true);
    setTimeout(() => {
      setProjectSwitched(false);
    }, 1000); // reset after 1 second
  };
  useEffect(() => {
    if (!notificationsLoading && notifications && notifications.length) {
      setNotificationsCount(
        notifications.users_notifications.filter(
          (notification) => notification.read === false
        ).length
      );
    }
  }, [notifications]);
  return (
    <nav
      className={`py-5 px-3 ${
        props.sidebarShown ? "pl-10" : "pl-16"
      } relative flex justify-between`}>
      <div className={`flex items-center`}>
        <IconButton
          className={`bg-gray-100 shadow-lg !p-2 w-8 h-8 hover:bg-gray-200 z-10 absolute ${
            props.sidebarShown ? "left-[14.5rem] xl:-left-4" : "left-2"
          } focus:!ring-0 border-none`}
          type="rounded"
          onClick={() => {
            props.setSidebarShown(!props.sidebarShown);
          }}>
          {
            <ChevronDown
              className={`!w-8 !h-8 border-none delay-500 duration-200 ${
                props.sidebarShown ? "rotate-90" : "-rotate-90"
              }`}
            />
          }
        </IconButton>
      </div>
      <div className="w-full pr-10 flex items-center justify-between">
        {loading && !currentProject && !insideManagement ? (
          <div
            role="status"
            className={`flex items-center animate-pulse ${props.className}`}>
            <span className="w-10 h-10 object-cover bg-gray-200 rounded-full flex items-center"></span>
            <div className="text-xs h-full flex flex-col justify-center ml-2">
              <div className="h-3 w-24 mb-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className="h-2 w-16 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
          </div>
        ) : (
          !error &&
          // !insideManagement &&
          projects?.length > 0 && (
            <Popover
              className={`relative ${projects?.length === 0 && "hidden"}`}>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`flex ${
                      projectSwitched && "animate-pulse"
                    } bg-inherit rounded-full outline-none items-center min-w-[250px]
                ${open ? "" : "text-opacity-90"}`}>
                    {/* <img
                      className="w-10 h-10 object-cover rounded-full"
                      src={currentProject?.logo || conLogo}
                      alt="Project logo"
                    /> */}
                    <span className="flex flex-col justify-center items-start ml-2">
                      <span className="flex items-center">
                        <span className="mr-1 font-medium text-primary leading-[1.2]">
                          {currentProject?.projectName}
                        </span>
                        <ChevronDown className="text-xs" />
                      </span>
                      <span className="text-[10px] font-medium text-dark-gray leading-none">
                        <LocationOnOutlinedIcon className="!w-4 !h-4 text-red-400" />
                        {currentProject?.site?.location}
                      </span>
                    </span>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel className="absolute left-1/2 z-[9] mt-3 min-w-[400px] max-w-sm -translate-x-1/3 transform px-4 sm:px-0 lg:max-w-3xl">
                      {({ close }) => (
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative flex flex-col max-h-72 bg-white">
                            <div className="p-3">
                              <SearchFromList
                                onClick={(project) => {
                                  changeProject(project);
                                  close();
                                  showAnimatation();
                                }}
                                list={projects}
                                propertiesKey="projectName"
                                placeholder="Search projects"
                              />
                            </div>
                            {projectsError && (
                              <p className="text-dark-gray my-2 text-center text-sm">
                                {projectsError?.message}
                              </p>
                            )}
                            {loading && (
                              <p className="text-dark-gray my-2 text-center text-sm">
                                Loading projects...
                              </p>
                            )}
                            <div className="max-h-64 overflow-y-auto vertical-scrollbar">
                              {!error &&
                                !loading &&
                                projects.map((project, index) => (
                                  <button
                                    key={index}
                                    onClick={() => {
                                      changeProject(project);
                                      close();
                                      showAnimatation();
                                    }}
                                    className={`cursor-pointer w-full flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-50 ${
                                      project.id === currentProject?.id &&
                                      "bg-secondary/30 hover:bg-secondary/30 rounded-none"
                                    }`}>
                                    <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                      {/* <img
                                        className="w-10 h-10 object-cover mr-2 rounded-full"
                                        src={project.logo || conLogo}
                                        alt="Project logo"
                                      /> */}
                                    </div>
                                    <div className="text-start">
                                      <p className="text-sm font-medium text-gray-900">
                                        {project.projectName}
                                      </p>
                                      <p className="text-xs text-gray-500">
                                        <LocationOnOutlinedIcon className="!w-4 !h-4" />
                                        {project.site.location} -{" "}
                                        {project.site.name}
                                      </p>
                                    </div>
                                  </button>
                                ))}
                            </div>
                          </div>
                          {/* {checkRoles(user, ["SU", "ADM"]) && (
                            <button
                              onClick={addProjectClickHandler}
                              type="alternative"
                              className="bg-gray-50 hover:bg-gray-100 p-4 flex items-center justify-start rounded-none w-full text-sm font-medium text-primary border-none rounded-b-lg"
                            >
                              <PlusCircle className="text-xl mr-2" />
                              Add a new project
                            </button>
                          )} */}
                        </div>
                      )}
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          )
        )}
        <div className="flex items-center text-lg rounded-lg py-2">
          {!companyLoading && company && (
            <>
              <img
                className="w-10 h-10 object-cover rounded-full mr-2"
                src={company.logo || conLogo}
                alt="Company logo"
              />
              <span className="flex items-center font-medium">
                <span className="mr-1 font-bold leading-none">
                  {company.name}
                </span>
                {/* <ChevronDown className="text-xs" /> */}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <Popover className={`relative`}>
          {({ open }) => (
            <>
              <Popover.Button
                // onClick={updateNotifications}
                className={`flex bg-inherit rounded-full outline-none items-center`}>
                <span
                  className={`inline-flex relative mr-3 justify-center items-center text-dark-gray p-2 text-sm font-medium text-center rounded-lg hover:bg-gray-100 hover:text-gray-700 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 ${props.className}`}>
                  <Bell className="text-xl border-none" />
                  {notificationsCount > 0 && (
                    <span className="absolute top-0 left-full -translate-x-3 font-medium bg-dark-red text-white text-xs rounded-full min-w-[16px] h-4 px-1">
                      {notificationsCount}
                    </span>
                  )}
                </span>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1">
                <Popover.Panel className="absolute left-1/2 max-h-[500px] vertical-scrollbar shadow-lg overflow-auto z-[9] mt-3 min-w-[300px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                  {({ close }) => (
                    <Notifications
                      onClick={(id) => {
                        close();
                        onNotificationClick(id);
                      }}
                      markAllAsReadLoading={markAllAsReadLoading}
                      markAllAsReadHandler={markAllAsReadHandler}
                      notifications={notifications}
                      notificationsLoading={notificationsLoading}
                      notificationsError={notificationsError}
                    />
                  )}
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>

        <Popover className={`relative`}>
          {({ open }) => (
            <>
              <Popover.Button
                // onClick={updateNotifications}
                className={`flex items-center w-max text-sm ml-4 rounded-lg px-2 py-2 outline-none bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700`}>
                <img
                  className="w-8 h-8 object-cover rounded-full mr-2"
                  src={
                    user?.photoURL ||
                    "https://cdn.pixabay.com/photo/2017/11/10/05/48/user-2935527_1280.png"
                  }
                  alt="Admin profile"
                />
                <span className="flex items-center font-medium">
                  <span className="mr-1 leading-none">{user?.fullName}</span>
                  {/* <ChevronDown className="text-xs" /> */}
                </span>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1">
                <Popover.Panel className="absolute right-0 max-h-[500px] bg-white vertical-scrollbar shadow-lg overflow-auto z-[9] mt-3 min-w-[300px] max-w-sm transform px-4 sm:px-0 lg:max-w-3xl">
                  {({ close }) => (
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative flex flex-col max-h-72">
                        <div className="max-h-64 overflow-y-auto vertical-scrollbar">
                          <button
                            onClick={() => {
                              navigate("/profile");
                              close();
                              showAnimatation();
                            }}
                            className={`cursor-pointer border-b w-full flex items-center px-4 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-50`}>
                            <div className="flex h-10 w-10 shrink-0 items-center justify-centersm:h-12 sm:w-12">
                              <User2Icon className="!w-5 !h-5" />
                            </div>
                            <div className="text-start">
                              <p className="text-sm font-medium">
                                Profile
                              </p>
                            </div>
                          </button>
                          <button
                            onClick={() => {
                              navigate("/security");
                              close();
                              showAnimatation();
                            }}
                            className={`cursor-pointer border-b w-full flex items-center px-4 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-50`}>
                            <div className="flex h-10 w-10 shrink-0 items-center justify-centersm:h-12 sm:w-12">
                              <ShieldOutlined className="!w-5 !h-5" />
                            </div>
                            <div className="text-start">
                              <p className="text-sm font-medium">
                                Security
                              </p>
                            </div>
                          </button>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          setIsConfirmShown(true);
                        }}
                        type="alternative"
                        className=" hover:bg-red-50 text-red-500 p-4 flex items-center justify-start rounded-none w-full text-sm font-medium border-none rounded-b-lg">
                        <LogOutIcon className="text-lg mr-2 rotate-180" /> Log
                        out
                      </button>
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
      <Toast
        type="error"
        show={error !== null}
        callback={() => {
          setError(null);
        }}
        message={error}
      />
      {isConfirmShown && (
        <Confirm
          confirmButtonText="I'm sure"
          confirmTitle="Logout"
          onClick={() => {
            signOut();
            clear();
            navigate("/login");
          }}
          onCancel={() => {
            setIsConfirmShown(false);
          }}>
          Are you sure you want to logout?
        </Confirm>
      )}
    </nav>
  );
};

export default Nav;
