import React, { useState, useRef, useEffect, useCallback } from "react";
import Spinner from "./Spinner";

function useOutsideAlerter(ref, setIsOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsOpen]);
}

const Dropdown = (props) => {
  const errorInputClasses =
    "bg-red-50 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-300 block w-full dark:bg-red-100 dark:border-red-400";
  const errorLabelClasses =
    "block mb-2 text-sm font-medium text-red-600 dark:text-red-500";

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [items, setItems] = useState([]);
  const wrapperRef = useRef(null);
  useEffect(() => {
    setValue(props.value);
    setItems(props.items);
  }, [props.value, props.items]);
  const changeHandler = useCallback(
    (e) => {
      const input_value = e.target.value;
      setValue(e.target.value);

      if (input_value) {
        // setIsOpen(false);
        setValue(input_value);
        setItems(
          props.items.filter((el) =>
            el[props.propertyName]
              .toLowerCase()
              .includes(input_value.toLowerCase())
          )
        );
      } else {
        // setIsOpen(true);
      }
    },
    [props.items]
  );
  const highlight = (str) => {
    if (str && value) {
      return str.replace(
        new RegExp(value.replace("+", ""), "gi"),
        (match) => `<mark>${match}</mark>`
      );
    } else return str;
  };
  useOutsideAlerter(wrapperRef, setIsOpen);
  return (
    <div className={`min-w-[250px] ${props.className}`}>
      {props.label && (
        <label
          htmlFor="dropdown"
          className={`block mb-2 text-sm font-medium text-gray-600 dark:text-white ${
            props.error && errorLabelClasses
          }`}
        >
          {props.label}
        </label>
      )}
      <span
        ref={wrapperRef}
        onBlur={props.onBlur}
        // onClick={() => setIsOpen(!isOpen)}
        className={`group w-full justify-between relative bg-gray-50 hover:bg-dark-gray/20 ${
          props.value ? "text-gray-900" : "text-gray-400"
        } focus:outline-none text-center rounded-lg inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
          (props.error || props.errorMessage) && errorInputClasses
        }`}
        type="button"
      >
        <input
          className={`rounded-lg focus:ring-4 focus:ring-primary/30 dark:focus:ring-primary/30 bg-transparent text-sm px-4 py-2.5 w-full outline-none ${props.inputClasses}`}
          // placeholder={
          //   !props.value && props.loading
          //     ? "Loading..."
          //     : !props.loading &&
          //       (props.value ||
          //         (props.items?.length
          //           ? props.items[0][props.propertyName] || props.items[0]
          //           : "No item found"))
          // }
          placeholder={
            !value
              ? props.placeholder
                ? props.placeholder
                : "Select an item"
              : props.loading && "Loading..."
          }
          onFocus={() => setIsOpen(true)}
          onChange={changeHandler}
          value={value ? value : ""}
        />
        {/* <span>
          {!props.value && props.loading && "Loading..."}
          {!props.loading &&
            (props.value ||
              (props.items?.length
                ? props.items[0][props.propertyName] || props.items[0]
                : "No item found"))}
        </span> */}
        <svg
          className={`w-4 h-4 mx-2 absolute right-2 ${
            isOpen ? "duration-200 rotate-180" : "duration-200 rotate-0"
          }`}
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
        <div
          id="dropdown"
          className={`${!isOpen && "hidden"} z-10 w-full absolute ${
            props.optionsTop ? "bottom-full" : "top-full"
          } left-1/2 -translate-x-1/2 group-focus:block max-h-64 overflow-y-auto vertical-scrollbar bg-white divide-y divide-gray-100 rounded-lg shadow min-w-44 dark:bg-gray-700`}
        >
          <ul
            className="py-2 z-50 text-start text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            {!props.loading && !props.errorMessage && items?.length ? (
              items.map((item, index) => (
                <li key={index}>
                  <button
                    type="button"
                    onClick={() => {
                      setIsOpen(false);
                      props.selectHandler(item, index);
                    }}
                    className={`w-full text-start block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${
                      (item[props.propertyName] === props.value ||
                        item === props.value) &&
                      "bg-gray-100"
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: highlight(item[props.propertyName] || item),
                    }}
                  >
                    {/* {props.propertyName
                      ? !props.loading &&
                        !props.errorMessage &&
                        highlight(item[props.propertyName])
                      : highlight(item)} */}
                  </button>
                </li>
              ))
            ) : props.loading ? (
              <li className="text-center">
                <Spinner />
              </li>
            ) : (
              <p className="text-center">No item found</p>
            )}

            {props.errorMessage && (
              <li className="text-center">{props.errorMessage}</li>
            )}
          </ul>
        </div>
      </span>
    </div>
  );
};

export default Dropdown;
