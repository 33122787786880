import { Tabs, Tab } from "../../components/UI/Tabs";
import milestoneIllustration from "../../assets/illustrations/milestones.svg";
import Button from "../../components/UI/Button";
import AddIcon from "@mui/icons-material/Add";
import ModelTrainingOutlinedIcon from "@mui/icons-material/ModelTrainingOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Warning } from "@mui/icons-material";
import { Gantt } from "gantt-task-react";
import PhasesCard from "../../components/UI/phases/phasesCard";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Table from "../../components/UI/Table";
import ListSkeleton from "../../components/UI/skeleton/ListSkeleton";
import RectangleSkeleton from "../../components/UI/skeleton/RectangleSkeleton";
import CurrentPhase from "../../components/UI/phases/CurrentPhase";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Progress from "../../components/UI/Progress";

const Main = ({
  selectMilestone,
  setSelectedMilestone,
  currentPhases,
  getMilestonesLoading,
  getMilestonesError,
  selectedMilestone,
  milestones,
}) => {
  const tabRef = useRef();
  const navigate = useNavigate();
  let completedCount = 0;
  let milestonesFiltered = [];
  milestones?.map((milestone) => {
    if (milestone.percentageCompleted >= 100) {
      completedCount++;
    }

    milestonesFiltered.push({
      id: milestone.id,
      name: milestone.name,
      startDate: moment(milestone.start).format("LL"),
      endDate: moment(milestone.end).format("LL"),
      progress: <Progress progress={milestone.percentageCompleted} />,
      budget: <span className="ml-3">{milestone.budget.toLocaleString()}</span>,
    });
  });
  return (
    <Tabs
      tabRef={tabRef}
      className={`${selectedMilestone ? "col-span-9" : "col-span-12"}`}
      otherElements={
        <Button
          onClick={() => {
            navigate("/phases/new");
            setSelectedMilestone(null);
          }}>
          <AddIcon />
          New Milestone
        </Button>
      }
      callback={(activeTab) => {
        if (activeTab === 1) {
          setSelectedMilestone(null);
        }
      }}>
      <Tab label="Milestones">
        <div className="min-h-full pt-5">
          <div>
            {getMilestonesLoading && !getMilestonesError ? (
              <>
                <div className="flex justify-center space-x-6 w-full">
                  <RectangleSkeleton className="w-44 !h-52" />
                  <RectangleSkeleton className="w-44 !h-52" />
                  <RectangleSkeleton className="w-44 !h-52" />
                </div>
                <ListSkeleton />
              </>
            ) : getMilestonesError ? (
              <p className="flex justify-center items-center">
                <Warning className="!w-4 !h-4 mr-1" />
                {getMilestonesError?.message}
              </p>
            ) : milestonesFiltered?.length ? (
              <div className="flex flex-col justify-center w-full">
                <div className="flex justify-center space-x-6 mb-5 w-full">
                  <PhasesCard
                    icon={<AutorenewIcon className="text-dark-blue" />}
                    title="Total Phases"
                    content={milestones?.length}
                    bgColor="bg-light-blue"
                  />
                  <PhasesCard
                    icon={<CheckOutlinedIcon className="text-green-500" />}
                    title="Completed"
                    content={completedCount}
                    bgColor="bg-green-100"
                  />
                  <PhasesCard
                    icon={
                      <ModelTrainingOutlinedIcon className="text-dark-orange" />
                    }
                    title="Current Phase"
                    content={
                      currentPhases && currentPhases.length ? (
                        <span className="text-2xl w-full inline-block overflow-hidden whitespace-nowrap text-ellipsis">
                          <CurrentPhase currentPhases={currentPhases} />
                        </span>
                      ) : (
                        "-"
                      )
                    }
                    tooltip={
                      currentPhases && currentPhases.length ? (
                        <CurrentPhase currentPhases={currentPhases} />
                      ) : (
                        "-"
                      )
                    }
                    bgColor="bg-light-orange"
                  />
                </div>
                <Table
                  title={`All Milestones(${milestones?.length || 0})`}
                  search={true}
                  searchList={milestonesFiltered}
                  searchPropertiesKey="name"
                  searchPlaceholder="Search a milestone"
                  delete={false}
                  checkbox={false}
                  // showDeleteManyConfirmation={showDeleteManyConfirmation}
                  checkboxClickHandler={selectMilestone}
                  headers={[
                    "Name",
                    "Start date",
                    "End date",
                    "Progress",
                    "Budget",
                  ]}
                  rows={milestonesFiltered}
                  errorMessage={
                    getMilestonesError && (
                      <p className="flex justify-center items-center">
                        <Warning className="!w-4 !h-4 mr-1" />
                        {getMilestonesError}
                      </p>
                    )
                  }
                />
              </div>
            ) : (
              <div className="space-y-2 self-center col-start-5 col-end-9 flex flex-col justify-center items-center">
                <img
                  className="w-72 mt-4 object-cover"
                  alt=""
                  src={milestoneIllustration}
                />
                <p className="text-gray-700 text-2xl mt-5">
                  No milestone found
                </p>
                <p className="text-dark-gray text-sm">
                  Start by creating your first milestone with in few steps
                </p>
              </div>
            )}
          </div>
        </div>
      </Tab>
      <Tab label="Gantt Chart">
        <div className="min-h-full pt-5">
          {getMilestonesError ? (
            <p className="flex justify-center items-center">
              <Warning className="!w-4 !h-4 mr-1" />
              {getMilestonesError?.message}
            </p>
          ) : getMilestonesLoading && !getMilestonesError ? (
            <p className="w-full text-center text-sm">Loading...</p>
          ) : !milestones?.length ? (
            <div className="space-y-2 self-center col-start-5 col-end-9 flex flex-col justify-center items-center">
              <img
                className="w-72 mt-4 object-cover"
                alt=""
                src={milestoneIllustration}
              />
              <p className="text-gray-700 text-2xl mt-5">No milestone found</p>
              <p className="text-dark-gray text-sm">
                Start by creating your first milestone with in few steps
              </p>
            </div>
          ) : (
            <Gantt
              name="Progress"
              tasks={milestones}
              fontSize="13px"
              columnWidth={50}
              rowHeight={25}
              headerHeight={35}
              barProgressColor={"#000"}
            />
          )}
        </div>
      </Tab>
    </Tabs>
  );
};

export default Main;
