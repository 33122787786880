import React, { Fragment, useState } from "react";

const Tabs = ({
  children,
  otherElements,
  className,
  customStyles,
  tabRef,
  middle,
  callback = () => {},
}) => {
  const tabs = children.filter((item) => item !== false);
  const [activeTab, setActiveTab] = useState(tabs[0].props.label);
  const handleClick = (e, newActiveTab, index) => {
    e.preventDefault();
    setActiveTab(newActiveTab);
    callback(index);
  };

  return (
    <div
      ref={tabRef}
      className={`mb-4 border-gray-200 dark:border-gray-700 ${className}`}
    >
      <div
        className={`bg-white p-3 rounded-md flex align-center justify-between ${customStyles}`}
      >
        <div className="flex flex-wrap -mb-px text-sm font-medium text-center">
          {tabs.map((child, index) => (
            <button
              key={child.props.label}
              className={`${
                activeTab === child.props.label
                  ? "text-gray-700 border-b-2 border-primary"
                  : ""
              } mx-2 text-gray-500 font-medium py-2`}
              onClick={(e) => handleClick(e, child.props.label, index)}
            >
              {child.props.label}
            </button>
          ))}
        </div>
        {otherElements}
      </div>
      {middle}
      <div className="py-4 h-full">
        {tabs.map((child) => {
          if (child.props.label === activeTab) {
            return (
              <Fragment key={child.props.label}>
                {child.props.children}
              </Fragment>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

const Tab = ({ label, children }) => {
  return (
    <div label={label} className="hidden">
      {children}
    </div>
  );
};
export { Tabs, Tab };
