import React, { Fragment, useEffect, useRef, useState } from "react";
import Card from "../../components/UI/Card";
import { gql, useLazyQuery } from "@apollo/client";
import moment from "moment";
import Toast from "../../components/UI/Toast";
import { useNavigate, useLocation } from "react-router-dom";
import "gantt-task-react/dist/index.css";
import { useProject } from "../../store/project-context";
import Progress from "../../components/UI/Progress";
import NoProject from "../../components/UI/NoProject";
import SelectedMilestone from "./SelectedMilestone";
import Main from "./Main";
import Details from "./Details";

const GET_MILESTONES = gql`
  query MyQuery($projectId: uuid!) {
    projects_milestones(
      order_by: { startDate: asc }
      where: { projectId: { _eq: $projectId } }
    ) {
      id
      endDate
      name
      startDate
      projectId
      completed_at
      milestone_equipments {
        id
        quantity
        unitPrice
        noOfHours
        type
        equipment {
          id
          name
          unit
        }
      }
      milestone_materials {
        id
        quantity
        unitPrice
        material {
          id
          name
          unit
        }
      }
      milestone_labors {
        id
        quantity
        unitPrice
        noOfHours
        labor {
          id
          type
        }
      }
      checklists {
        id
        title
        isChecked
      }
      quantifiable_tasks {
        id
        name
        quantity
        unit
        unitRate
        completed_at
        work_permit {
          id
          status
          approved_at
        }
        material_permit {
          id
          status
          approved_at
        }
      }
      tests {
        name
        id
        isChecked
      }
      reports(where: { inactive: { _neq: true } }) {
        checklist
        equipments
        labor
        materials
        tasks
      }
    }
  }
`;
const PhasesPage = () => {
  const location = useLocation();
  const { currentProject } = useProject();
  const [success, setSuccess] = useState(null);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [error, setError] = useState(null);
  const [milestones, setMilestones] = useState([]);
  const [currentPhases, setCurrentPhases] = useState(null);
  const [detailsShown, setDetailsShown] = useState(false);
  const cardRef = useRef();
  const [
    getMilestones,
    { data, loading: getMilestonesLoading, error: getMilestonesError, refetch },
  ] = useLazyQuery(GET_MILESTONES);
  useEffect(() => {
    if (currentProject?.id) {
      getMilestones({
        variables: { projectId: currentProject?.id },
      });
    }
  }, [currentProject?.id]);
  useEffect(() => {
    async function fetchData() {
      if (currentProject?.id && !getMilestonesLoading) {
        console.log(data?.projects_milestones);
        if (data?.projects_milestones) {
          const milestonesData = data?.projects_milestones?.map(
            (milestone, index) => {
              // if (new Date(milestone.startDate) < new Date(milestone.endDate)) {
              let totalEquipmentCost = 0;
              milestone.milestone_equipments?.map((el) => {
                if (el.type === "RENTED") {
                  totalEquipmentCost +=
                    el.quantity * el.unitPrice * el.noOfHours;
                } else if (el.type === "PURCHASED") {
                  totalEquipmentCost += el.quantity * el.unitPrice;
                }
              });
              // console.log(totalEquipmentCost);
              let totalMaterialCost = 0;
              milestone.milestone_materials?.map((el) => {
                totalMaterialCost += el.quantity * el.unitPrice;
              });
              // console.log(totalMaterialCost);
              let totalLaborCost = 0;
              milestone.milestone_labors?.map((el) => {
                totalLaborCost += el.quantity * el.unitPrice * el.noOfHours;
              });
              // console.log(totalLaborCost);
              let checkedChecklistCount = 0;
              milestone.checklists?.map((el) => {
                if (el.isChecked) {
                  checkedChecklistCount++;
                }
              });
              const tasks = milestone.quantifiable_tasks?.map((task) => {
                let totalQuantity = 0;
                milestone.reports?.map((el) => {
                  JSON.parse(el.tasks).map((reportTask) => {
                    if (reportTask.id === task.id) {
                      totalQuantity += parseInt(reportTask.quantity);
                    }
                  });
                });
                return {
                  ...task,
                  reportQuantity: totalQuantity,
                  progress: Math.floor((totalQuantity / task.quantity) * 100),
                };
              });
              let totalEquipmentBudgetSpent = 0;
              let totalMaterialBudgetSpent = 0;
              let totalLaborBudgetSpent = 0;
              // let totalTasksBudgetSpent = 0;

              let percentageCompleted = 0;
              tasks.map((task) => {
                console.log(task.progress);
                percentageCompleted += task.progress;
              });
              if (tasks.length > 0) {
                percentageCompleted /= tasks.length;
              } else {
                percentageCompleted = 0;
              }
              const checklistAverage = Math.floor(
                (checkedChecklistCount / milestone.checklists.length) * 100
              );
              if (checklistAverage > 0) {
                percentageCompleted = Math.floor(
                  (percentageCompleted + checklistAverage) / 2
                );
              }
              milestone.reports?.map((rep) => {
                console.log(JSON.parse(rep.materials));
                JSON.parse(rep.materials).map((item) => {
                  if (item.totalPrice === NaN || item.quantity === NaN) return;
                  totalMaterialBudgetSpent += parseFloat(item.totalPrice);
                });
                JSON.parse(rep.equipments).map((item) => {
                  if (item.unitPrice === NaN || item.quantity === NaN) return;
                  totalEquipmentBudgetSpent += item.unitPrice * item.quantity;
                });
                JSON.parse(rep.labor).map((item) => {
                  if (item.quantity === NaN || item.payPerWorker === NaN)
                    return;
                  totalLaborBudgetSpent += item.quantity * item.payPerWorker;
                });
                // JSON.parse(rep.tasks).map((item) => {
                //   if (!item.unitRate || item.unitRate === NaN || item.quantity === NaN) return;
                //   totalTasksBudgetSpent += parseFloat(item.unitRate) * item.quantity;
                // });
              });
              let currentStatus;
              const today = new Date();
              if (milestone.completed_at || percentageCompleted >= 100) {
                currentStatus = "COMPLETE";
              } else if (
                today > new Date(milestone.startDate) &&
                today < new Date(milestone.endDate)
              ) {
                currentStatus = "ON_TRACK";
              } else if (today < new Date(milestone.startDate)) {
                currentStatus = "TO_DO";
              } else if (today > new Date(milestone.endDate)) {
                currentStatus = "WARNING";
              }
              return {
                start: new Date(milestone.startDate),
                end: new Date(milestone.endDate),
                name: milestone.name,
                currentStatus,
                checklists: milestone.checklists,
                quantifiable_tasks: milestone.quantifiable_tasks,
                quantifiable_tasks_edited: tasks,
                equipments: milestone.milestone_equipments,
                labors: milestone.milestone_labors,
                materials: milestone.milestone_materials,
                tests: milestone.tests,
                projectId: milestone.projectId,
                milestoneId: milestone.id,
                id: "Task " + index,
                type: "task",
                reports: milestone.reports,
                percentageCompleted,
                // isDisabled: true,
                budget: totalEquipmentCost + totalMaterialCost + totalLaborCost,
                totalEquipmentCost,
                totalMaterialCost,
                totalLaborCost,
                budgetSpent:
                  totalEquipmentBudgetSpent +
                  totalMaterialBudgetSpent +
                  totalLaborBudgetSpent,
                totalEquipmentBudgetSpent,
                totalMaterialBudgetSpent,
                totalLaborBudgetSpent,
                styles: {
                  progressColor: "#4592af",
                  progressSelectedColor: "#226089",
                },
              };
              // }
            }
          );
          let currentPhases = [];
          milestonesData.map((milestone) => {
            const today = new Date();
            if (
              today >= new Date(milestone.start) &&
              today <= new Date(milestone.end)
            ) {
              currentPhases.push(milestone.name);
            }
          });
          console.log(currentPhases);
          if (selectedMilestone?.milestoneId) {
            setSelectedMilestone(
              milestonesData.find(
                (el) => el.milestoneId === selectedMilestone.milestoneId
              )
            );
          } else {
            setSelectedMilestone(null);
          }
          setCurrentPhases(currentPhases);
          setMilestones(milestonesData.filter((el) => el !== undefined));
        }
      }
    }
    fetchData();
  }, [currentProject?.id, location?.state?.success, data?.projects_milestones]);

  useEffect(() => {
    if (location?.state?.success) {
      refetch();
      setSuccess(location?.state?.success);
      window.history.replaceState({}, document.title);
    }
  }, [location?.state?.success]);
  console.log(selectedMilestone);

  const selectMilestone = (id) => {
    if (id === null) {
      setSelectedMilestone(null);
      return;
    }
    milestones.map((milestone) => {
      if (milestone.id === id) {
        setSelectedMilestone(milestone);
        return milestone;
      } else {
        return;
      }
    });
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
    // tabRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (currentProject) {
    return (
      <div ref={cardRef} className="m-3 relative h-full grid grid-cols-12">
        {detailsShown ? (
          <Details
            selectedMilestone={selectedMilestone}
            setDetailsShown={setDetailsShown}
            refetch={refetch}
          />
        ) : (
          <Main
            selectMilestone={selectMilestone}
            setSelectedMilestone={setSelectedMilestone}
            currentPhases={currentPhases}
            getMilestonesLoading={getMilestonesLoading}
            getMilestonesError={getMilestonesError}
            selectedMilestone={selectedMilestone}
            milestones={milestones}
          />
        )}
        {milestones?.length > 0 && (
          <Card
            // cardRef={cardRef}
            className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
              selectedMilestone && !detailsShown
                ? "translate-x-0 col-span-3"
                : "translate-x-full !bg-transparent"
            }`}>
            {selectedMilestone && !detailsShown ? (
              <SelectedMilestone
                setDetailsShown={setDetailsShown}
                selectedMilestone={selectedMilestone}
              />
            ) : (
              <Fragment></Fragment>
            )}
          </Card>
        )}
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
        <Toast
          type="success"
          show={success !== null}
          callback={() => {
            setSuccess(null);
          }}
          message={success}
        />
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default PhasesPage;
