import { gql, useMutation, useSubscription } from "@apollo/client";
import React, { useContext } from "react";
import { useAuthUser } from "react-auth-kit";
import { useProject } from "./project-context";

const NotificationContext = React.createContext();

const GET_NOTIFICATIONS = gql`
  subscription MySubscription($user_id: uuid!) {
    users_notifications(
      where: { user_id: { _eq: $user_id } }
      order_by: { created_at: desc }
    ) {
      id
      read
      tag
      title
      link
      description
      created_at
    }
  }
`;

const MARK_ALL_AS_READ = gql`
  mutation MyMutation($user_id: uuid!) {
    update_users_notifications(
      where: { user_id: { _eq: $user_id } }
      _set: { read: true }
    ) {
      affected_rows
    }
  }
`;

const MARK_AS_READ = gql`
  mutation MyMutation($id: uuid!) {
    update_users_notifications_by_pk(
      pk_columns: { id: $id }
      _set: { read: true }
    ) {
      id
    }
  }
`;

const SEND_NOTIFICATION = gql`
  mutation MyMutation($object: NotificationInput!) {
    sendNotification(object: $object) {
      status
    }
  }
`;
export function useNotification() {
  return useContext(NotificationContext);
}

export function NotificationContextProvider(props) {
  const auth = useAuthUser();
  const user = auth();
  const token = localStorage.getItem("token");

  // if (!token) {
  //   console.log("No token found. Subscription won't run.");
  //   return null; // Or return a loading state/UI
  // }

  const {
    data: notifications,
    loading: notificationsLoading,
    error: notificationsError,
  } = useSubscription(GET_NOTIFICATIONS, {
    variables: {
      user_id: user.id,
    },
    skip: token ? false : true, // Skip the subscription if the token doesn't exist
  });

  const [markAllAsRead, { loading: markAllAsReadLoading }] =
    useMutation(MARK_ALL_AS_READ);
  const [markAsRead] = useMutation(MARK_AS_READ);
  const [addNotification] = useMutation(SEND_NOTIFICATION);
  const { currentProject } = useProject();
  console.log("notificationsError");
  console.log(notificationsError);
  console.log(notifications);

  const sendNotification = (data) => {
    let user_ids = [];
    currentProject.contractors.map(({ user }) => {
      user_ids.push(user.id);
    });
    currentProject.clients.map(({ user }) => {
      user_ids.push(user.id);
    });
    console.log(user_ids);
    console.log(currentProject);
    addNotification({
      variables: {
        object: {
          ...data,
          user_ids: data.users ? data.users : [user.id],
        },
      },
    });
  };
  const contextValue = {
    notifications: notifications ? notifications : [],
    notificationsLoading,
    notificationsError,
    markAllAsReadLoading,
    markAllAsRead,
    markAsRead,
    sendNotification,
  };
  return (
    <NotificationContext.Provider value={contextValue}>
      {props.children}
    </NotificationContext.Provider>
  );
}

export default NotificationContext;
