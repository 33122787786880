import React, { useEffect, useState } from "react";
import Input from "../UI/Input";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import useInput from "../../hooks/use-input";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useProject } from "../../store/project-context";
import Dropdown from "../UI/Dropdown";
import Radio from "../UI/Radio";
import Textarea from "../UI/Textarea";
import { X } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import IconButton from "../UI/IconButton";
import { PlusIcon } from "lucide-react";

const CREATE_WORK_PERMIT = gql`
  mutation MyMutation($object: CreateWorkPermitInput!) {
    create_work_permit(object: $object) {
      status
    }
  }
`;

const CREATE_MATERIAL_PERMIT = gql`
  mutation MyMutation($object: CreateMaterialPermitInput!) {
    create_material_permit(object: $object) {
      status
    }
  }
`;
const GET_WORK_PERMITS = gql`
  query MyQuery($milestoneId: uuid!) {
    projects_work_permits(
      where: {
        status: { _eq: "APPROVED" }
        quantifiable_task: { milestoneId: { _eq: $milestoneId } }
      }
    ) {
      id
      status
      quantifiable_task {
        id
        name
      }
    }
  }
`;
const GET_MATERIALS = gql`
  query MyQuery($companyId: uuid!) {
    projects_materials(
      where: {
        _or: [
          { companyId: { _eq: $companyId } }
          { companyId: { _is_null: true } }
        ]
        storages: { quantity: { _gt: 0 } }
      }
      order_by: { created_at: desc }
    ) {
      id
      name
      unit
      storages {
        quantity
      }
    }
  }
`;
const NewPermit = (props) => {
  const { currentProject, company } = useProject();
  const [permitType, setPermitType] = useState("WORK");
  const { selectedMilestone, refetch } = props;
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [approvedWorkPermits, setApprovedWorkPermits] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [selectedApprovedPermit, setSelectedApprovedPermit] = useState();
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const { data: workPermits, loading: getWorkPermitsLoading } = useQuery(
    GET_WORK_PERMITS,
    {
      variables: { milestoneId: selectedMilestone.milestoneId },
      fetchPolicy: "network-only",
    }
  );
  const { data: availableMaterials, loading: getMaterialsLoading } = useQuery(
    GET_MATERIALS,
    {
      variables: { companyId: company?.id },
      fetchPolicy: "network-only",
    }
  );

  const [createMaterialPermit, { loading: isMaterialPermitSubmitting }] =
    useMutation(CREATE_MATERIAL_PERMIT);
  const [createWorkPermit, { loading: isWorkPermitSubmitting }] =
    useMutation(CREATE_WORK_PERMIT);

  useEffect(() => {
    if (workPermits && !getWorkPermitsLoading) {
      setApprovedWorkPermits(
        workPermits.projects_work_permits.map(
          (permit) => permit.quantifiable_task
        )
      );
    }
  }, [workPermits]);
  useEffect(() => {
    if (availableMaterials && !getMaterialsLoading) {
      setMaterials(
        availableMaterials.projects_materials.map((material) => material)
      );
    }
  }, [availableMaterials]);

  const {
    value: quantity,
    inputChangeHandler: quantityChangeHandler,
    inputBlurHandler: quantityBlurHandler,
    reset: resetQuantity,
  } = useInput((value) => value !== "");

  const {
    value: description,
    inputChangeHandler: descriptionChangeHandler,
    reset: resetDescription,
  } = useInput((value) => value !== "");

  let formIsValid = false;
  if (permitType === "WORK") {
    formIsValid = selectedTasks.length;
  } else {
    formIsValid = selectedApprovedPermit && selectedMaterials.length;
  }

  const submitHandler = async (e) => {
    if (!currentProject?.id) {
      toast.error("No project selected");
      return;
    }
    try {
      if (permitType === "WORK") {
        const response = await createWorkPermit({
          variables: {
            object: {
              task_ids: selectedTasks.map((task) => task.id),
            },
          },
        });
        if (response?.data?.create_work_permit?.status) {
          toast.success("Work permit requested successfully");
        }
      } else {
        const response = await createMaterialPermit({
          variables: {
            object: {
              task_id: selectedApprovedPermit.id,
              materials: JSON.stringify(selectedMaterials),
              description,
            },
          },
        });
        if (response?.data?.create_material_permit?.status) {
          toast.success("Material permit requested successfully");
        }
      }
      hideModal();
      refetch();
    } catch (e) {
      console.log(e);
    }
  };
  console.log(selectedMilestone);

  // useEffect(() => {
  //   console.log(selectedTask, editMode);

  //   if (selectedTask && editMode) {
  //     materialChangeHandler({ name: selectedTask?.name });
  //     quantityChangeHandler(selectedTask?.quantity);
  //     unitPriceChangeHandler(selectedTask?.price);
  //     descriptionChangeHandler(selectedTask?.description);
  //     setPermitType(selectedTask?.type);
  //     setMilestone({ name: selectedTask?.phases });
  //   }
  // }, [
  //   editMode,
  //   selectedTask,
  //   materialChangeHandler,
  //   quantityChangeHandler,
  //   unitPriceChangeHandler,
  //   descriptionChangeHandler,
  //   setMilestone,
  //   setPermitType,
  // ]);

  const hideModal = () => {
    resetQuantity();
    resetDescription();
    props.hideModal();
  };
  const addTask = (item, index) => {
    if (selectedTasks.indexOf(item.name) > -1) {
      return;
    }
    let found = false;
    selectedTasks.map((task) => {
      if (item.id === task.id) {
        found = true;
        return;
      }
    });
    if (found) {
      return;
    }
    setSelectedTasks((tasks) => [...tasks, item]);
  };
  const removeTask = (i) => {
    setSelectedTasks(selectedTasks.filter((_, index) => i !== index));
  };
  const removeMaterial = (i) => {
    setSelectedMaterials(selectedMaterials.filter((_, index) => i !== index));
  };
  const addMaterial = () => {
    if (selectedMaterials.indexOf(selectedMaterial.id) > -1) {
      return;
    }
    let found = false;
    selectedMaterials.map((material) => {
      if (selectedMaterial.id === material.id) {
        found = true;
        return;
      }
    });
    if (found) {
      return;
    }
    setSelectedMaterials((materials) => [
      ...materials,
      { ...selectedMaterial, quantity },
    ]);
    setSelectedMaterial(null);
    resetQuantity(0);
  };
  if (props.isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[700px]"
          isShown={true} //props.isShown
          hideModal={hideModal}
          modalTitle="Request a new permit"
          modalBottom={
            <div className="flex justify-end mr-4">
              <Button className="mr-3" type="light" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                disabled={!formIsValid}
                isLoading={isWorkPermitSubmitting || isMaterialPermitSubmitting}
                onClick={submitHandler}
                type="submit">
                Send Request
              </Button>
            </div>
          }>
          <p className="text-dark-gray text-sm ">
            Send work permit to the consultant or a work permit for your
            supervisor.
          </p>
          <form className="space-y-3 mt-5 flex flex-col justify-between h-fit">
            <div className="flex space-x-4">
              <Radio
                checked={permitType === "WORK"}
                id="ch_work"
                label="Work Permit"
                onChange={() => setPermitType("WORK")}
              />
              <Radio
                checked={permitType === "MATERIAL"}
                id="ch_material"
                label="Material Permit"
                onChange={() => setPermitType("MATERIAL")}
              />
            </div>
            {permitType === "WORK" ? (
              <>
                <Dropdown
                  selectHandler={addTask}
                  label="Task name"
                  helperText={<>Tasks are required</>}
                  placeholder="Eg. Excavation and Earthworks"
                  items={selectedMilestone.quantifiable_tasks_edited.filter(
                    (task) => !task.work_permit
                  )}
                  propertyName="name"
                  required={true}
                  name="material"
                />
                <div className="w-full flex flex-col gap-3">
                  {selectedTasks.length !== 0 &&
                    selectedTasks.map((task, index) => (
                      <p
                        key={index}
                        className="bg-primary/10 flex justify-between items-center text-primary text-xs rounded-md px-3 py-2">
                        {task.name}
                        <button
                          onClick={() => removeTask(index)}
                          type="button"
                          className="text-lg ml-2 hover:text-red-500">
                          <X />
                        </button>
                      </p>
                    ))}
                </div>
              </>
            ) : (
              <>
                <Dropdown
                  selectHandler={(e) => {
                    setSelectedApprovedPermit(e);
                  }}
                  value={selectedApprovedPermit?.name}
                  label="Choose Approved Work Permit"
                  helperText={<>Approved work permit is required</>}
                  placeholder="Eg. Excavation and Earthworks"
                  items={approvedWorkPermits}
                  propertyName="name"
                  required={true}
                  name="material"
                />
                <div className="flex justify-between items-end w-full">
                  <Dropdown
                    selectHandler={(e) => {
                      setSelectedMaterial(e);
                    }}
                    value={selectedMaterial?.name}
                    label="Material name"
                    helperText={<>Material is required</>}
                    placeholder="Eg. Cement"
                    items={materials}
                    propertyName="name"
                    required={true}
                    name="material"
                  />
                  <Input
                    value={quantity}
                    onChange={quantityChangeHandler}
                    onBlur={quantityBlurHandler}
                    helperText={<>Quantity is required</>}
                    label="Quantity"
                    placeholder="15"
                    type="number"
                  />
                  <IconButton
                    disabled={!selectedMaterial || !quantity}
                    className="w-10 h-10 bg-white/50 text-primary"
                    type="rounded"
                    onClick={addMaterial}>
                    <PlusIcon className="!w-10 !h-10 text-xl" />
                  </IconButton>
                </div>
                <div className="w-full flex flex-col gap-3">
                  {selectedMaterials.length !== 0 &&
                    selectedMaterials.map((material, index) => (
                      <p
                        key={material.id}
                        className="bg-primary/10 flex justify-between items-center text-primary text-xs rounded-md px-3 py-2">
                        {material.name} - {material.quantity} {material.unit}
                        <button
                          onClick={() => removeMaterial(index)}
                          type="button"
                          className="text-lg ml-2 hover:text-red-500">
                          <X />
                        </button>
                      </p>
                    ))}
                </div>
                <Textarea
                  label="Description"
                  onChange={descriptionChangeHandler}
                  value={description}
                  placeholder="Description"
                />
              </>
            )}
          </form>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default NewPermit;
