import React, { Fragment, useEffect, useState } from "react";
import Checkbox from "./Checkbox";
import SearchFromList from "./SearchFromList";

const Table = (props) => {
  const convertedRows = [];
  const [allSelected, setAllSelected] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [activeRow, setActiveRow] = useState(null);
  // console.log("activeRow");
  // console.log(activeRow);
  
  props.rows &&
  props.rows.map((row) => {
    convertedRows.push(Object.values(row));
  });
  // console.log(convertedRows);
  useEffect(() => {
    setSelectedList([]);
    setAllSelected(false);
  }, []);
  const selectAll = (checked) => {
    setSelectedList([]);
    if (checked) {
      convertedRows.map((row) => {
        setSelectedList((list) => [...list, row[0]]);
      });
    }
    setAllSelected((prevChecked) => (prevChecked = checked));
  };
  const checkboxClickHandler = (checked, id) => {
    if (checked) {
      if (selectedList.length + 1 === convertedRows.length) {
        setAllSelected(checked);
      }
      setSelectedList([...selectedList, id]);
    } else {
      setAllSelected(checked);
      setSelectedList(selectedList.filter((selectedId) => selectedId !== id));
    }
  };
  return (
    <div className="vertical-scrollbar bg-white">
      {props.otherProp && (
        <div className="flex px-4 pt-2 justify-between items-center mb-2">
          <h1>{props.otherProp}</h1>
        </div>
      )}
      {convertedRows.length !== 0 && (
        <div className="flex px-4 pt-2 justify-between items-center mb-2">
          {props.title && (
            <div className="font-semibold dark:bg-gray-900">
              <h1>{props.title}</h1>
            </div>
          )}
          <div className="flex items-center">
            {props.delete && selectedList.length !== 0 && (
              <button
                onClick={() => props.showDeleteManyConfirmation(selectedList)}
                disabled={selectedList.length === 0}
                className="text-sm font-semibold disabled:text-red-400 text-red-600 hover:text-red-400 mr-5">
                Remove({selectedList.length})
              </button>
            )}
            {props.search && (
              <SearchFromList
                onClick={(item) => {
                  if (activeRow === item.id) {
                    setActiveRow(null);
                    props.checkboxClickHandler && props.checkboxClickHandler(null);
                  } else {
                    props.checkboxClickHandler && props.checkboxClickHandler(item.id);
                    setActiveRow((value) => (value = item.id));
                  }
                }}
                list={props.searchList}
                propertiesKey={props.searchPropertiesKey}
                placeholder={props.searchPlaceholder}
              />
            )}
          </div>
        </div>
      )}
      <div className="horizontal-scrollbar min-h-[60vh] relative overflow-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {props.headers && props.checkbox && (
                <th scope="col" className="p-4 text-center block pl-6 px-3">
                  <div className="flex items-center">
                    {convertedRows.length !== 0 && (
                      <Checkbox
                        allSelected={allSelected}
                        checked={allSelected}
                        onClick={(checked) => selectAll(checked)}
                      />
                    )}
                  </div>
                </th>
              )}
              {props.headers &&
                props.headers.map((value) => (
                  <th key={value} scope="col" className="px-6 py-4 text-center">
                    {value}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {convertedRows &&
              !props.errorMessage &&
              convertedRows.map((row, index) => (
                <tr
                  key={row[0]}
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-light-gray"
                  } hover:cursor-pointer border-b ${
                    props.checkboxClickHandler && activeRow === row[0] && "!bg-secondary/20"
                  } hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600 text-center`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (activeRow === row[0]) {
                      setActiveRow(null);
                      props.checkboxClickHandler &&
                        props.checkboxClickHandler(null);
                    } else {
                      props.checkboxClickHandler &&
                        props.checkboxClickHandler(row[0]);
                      setActiveRow((value) => (value = row[0]));
                    }
                  }}>
                  {props.checkbox && (
                    <td className="whitespace-nowrap py-4">
                      <Checkbox
                        className="ml-3"
                        selectedList={selectedList}
                        id={row[0]}
                        onClick={(checked) => {
                          checkboxClickHandler(checked, row[0]);
                        }}
                      />
                    </td>
                  )}
                  {row.map(
                    (value, index) =>
                      value !== row[0] &&
                      index <= props.headers.length && (
                        <td
                          key={row[0] + index}
                          className={`whitespace-nowrap max-w-2xl break-words overflow-hidden text-xs px-4 py-4`}>
                          {value}
                        </td>
                      )
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        {!props.errorMessage && convertedRows.length === 0 && (
          <p className="w-full text-center text-xs text-dark-gray my-5 m-auto">
            No data found
          </p>
        )}
        {props.errorMessage && (
          <p className="w-full text-center text-xs text-dark-gray my-5 m-auto">
            {props.errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default Table;
