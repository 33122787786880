import { Tabs, Tab } from "../../components/UI/Tabs";
import Table from "../../components/UI/Table";
import { useRef, useState } from "react";
import moment from "moment";
import Progress from "../../components/UI/Progress";
import { ArrowLeftShort, CaretRightFill } from "react-bootstrap-icons";
import Dropdown from "../../components/UI/Dropdown";
import { capitalizeFirstLetter } from "../../functions/extra";
import IconButton from "../../components/UI/IconButton";
import Button from "../../components/UI/Button";
import NewPermit from "../../components/Forms/NewPermit";
import ApproveWorkPermit from "../../components/Forms/ApproveWorkPermit";
import ApproveMaterialPermit from "../../components/Forms/ApproveMaterialPermit";
import { useAuthUser } from "react-auth-kit";

const Details = ({ selectedMilestone, setDetailsShown, refetch }) => {
  const tabRef = useRef();
  const [activeResource, setActiveResource] = useState("Materials");
  const [newPermitModalIsShown, setNewPermitModalIsShown] = useState(false);
  const [workPermitId, setWorkPermitId] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const auth = useAuthUser();
  const user = auth();
  let tasksFiltered = [];
  selectedMilestone?.quantifiable_tasks_edited?.map((task) => {
    tasksFiltered.push({
      id: task.id,
      name: task.name,
      startDate:
        task.work_permit && task.work_permit.approved_at
          ? moment(task.work_permit.approved_at).format("LL")
          : "-",
      endDate: task.completed_at ? moment(task.completed_at).format("LL") : "-",
      progress: (
        <div className="flex flex-col">
          <span>
            {task.reportQuantity}/{task.quantity} {task.unit}
          </span>
          <Progress progress={task.progress} />
        </div>
      ),
      status: task.work_permit ? (
        <span
          className={`ml-3 px-2 py-1 rounded-xl ${task.work_permit.status.toLowerCase()}-with-bg`}>
          {task.work_permit.status}
        </span>
      ) : (
        <>-</>
      ),
      work_permit: task.work_permit ? (
        <span
          onClick={() => {
            setWorkPermitId(task.work_permit.id);
          }}
          className="ml-3 text-primary hover:underline">
          View
        </span>
      ) : (
        <>-</>
      ),
      material_permit: task.material_permit ? (
        <span
          onClick={() => {
            setTaskId(task.id);
          }}
          className="ml-3 text-primary hover:underline">
          View
        </span>
      ) : (
        <>-</>
      ),
    });
  });
  const materials = selectedMilestone?.materials.map((material) => {
    return {
      id: material.material.id,
      name: material.material.name,
      unit: material.material.unit,
      quantity: material.quantity,
      unitPrice: material.unitPrice.toLocaleString(),
    };
  });
  const equipment = selectedMilestone?.equipments.map((eq) => {
    return {
      id: eq.equipment.id,
      name: eq.equipment.name,
      unit: eq.equipment.unit,
      quantity: eq.quantity,
      unitPrice: eq.unitPrice.toLocaleString(),
    };
  });
  const labor = selectedMilestone?.labors.map((lab) => {
    return {
      id: lab.labor.id,
      type: lab.labor.type,
      unit: "hrs",
      quantity: lab.quantity,
      unitPrice: lab.unitPrice.toLocaleString(),
    };
  });
  let resource = materials;
  switch (activeResource) {
    case "Materials":
      resource = materials;
      break;
    case "Equipment":
      resource = equipment;
      break;
    case "Labor":
      resource = labor;
      break;
    default:
      resource = materials;
  }
  const selectHandler = (e) => {
    console.log(e);

    if (e === "Materials") {
      setActiveResource("Materials");
    } else if (e === "Equipment") {
      setActiveResource("Equipment");
    } else if (e === "Labor") {
      setActiveResource("Labor");
    }
  };
  const hideModal = () => {
    setNewPermitModalIsShown(false);
    setWorkPermitId(null);
    setTaskId(null);
  };

  if (!selectedMilestone) {
    return (
      <h3 className="text-center mt-20 col-span-12">No Milestone Selected</h3>
    );
  }
  return (
    <div className="flex flex-col items-center justify-center w-full col-span-12">
      <h1 className="font-semibold text-xl flex w-full justify-start mb-4 items-center">
        <IconButton
          onClick={() => {
            setDetailsShown(false);
          }}
          className={`bg-gray-100 !p-1 focus:!ring-0 border-none mr-3`}
          type="rounded">
          <ArrowLeftShort className="text-3xl " />
        </IconButton>
        {selectedMilestone.name}
      </h1>
      <Tabs
        tabRef={tabRef}
        className={`w-full`}
        otherElements={
          user.roles.find((role) => role.code === "CNTR") && (
            <Button
              onClick={() => {
                setNewPermitModalIsShown(true);
              }}>
              Request Permit
            </Button>
          )
        }>
        <Tab label="Tasks">
          <Breadcrumb
            milestoneName={selectedMilestone.name}
            setDetailsShown={setDetailsShown}
            tab="Tasks"
          />
          <div className="min-h-full pt-5">
            <Table
              headers={
                user.roles.find((role) => role.code === "SITE_ADM")
                  ? [
                      "Item",
                      "Start date",
                      "End date",
                      "Progress",
                      "Status",
                      "Work Permit",
                      "Material Permit",
                    ]
                  : ["Item", "Start date", "End date", "Progress", "Status"]
              }
              rows={tasksFiltered}
            />
          </div>
        </Tab>
        <Tab label="Resources">
          <div className="flex justify-between">
            <Breadcrumb
              milestoneName={selectedMilestone.name}
              setDetailsShown={setDetailsShown}
              tab="Resources"
            />

            <Dropdown
              className="w-40"
              inputClasses="border"
              selectHandler={selectHandler}
              value={activeResource}
              items={["Materials", "Equipment", "Labor"]}
            />
          </div>
          <div className="min-h-full pt-5">
            <Table
              headers={["Name", "Unit", "Quantity", "Price"]}
              rows={resource}
            />
          </div>
        </Tab>
      </Tabs>
      {newPermitModalIsShown &&
        user.roles.find((role) => role.code === "CNTR") && (
          <NewPermit
            refetch={refetch}
            selectedMilestone={selectedMilestone}
            isShown={newPermitModalIsShown}
            hideModal={hideModal}
          />
        )}
      {workPermitId && user.roles.find((role) => role.code === "SITE_ADM") && (
        <ApproveWorkPermit
          refetch={refetch}
          permitId={workPermitId}
          isShown={!!workPermitId}
          hideModal={hideModal}
        />
      )}
      {taskId && user.roles.find((role) => role.code === "SITE_ADM") && (
        <ApproveMaterialPermit
          refetch={refetch}
          taskId={taskId}
          isShown={!!taskId}
          hideModal={hideModal}
        />
      )}
    </div>
  );
};

const Breadcrumb = ({ milestoneName, tab, setDetailsShown }) => {
  return (
    <div className="text-sm pl-2 my-4 flex items-center gap-2 text-gray-500">
      <span
        className="hover:underline cursor-pointer"
        onClick={() => {
          setDetailsShown(false);
        }}>
        Milestones
      </span>
      <CaretRightFill /> {capitalizeFirstLetter(milestoneName.toLowerCase())}{" "}
      <CaretRightFill /> {tab}
    </div>
  );
};
export default Details;
