import React, { useEffect, useState } from "react";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useProject } from "../../store/project-context";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../functions/extra";
import { DotIcon } from "lucide-react";

const UPDATE_WORK_PERMIT = gql`
  mutation MyMutation($object: UpdateWorkPermitInput!) {
    update_work_permit(object: $object) {
      status
    }
  }
`;
const GET_WORK_PERMIT = gql`
  query MyQuery($id: uuid!) {
    projects_work_permits_by_pk(id: $id) {
      id
      status
      created_by_user {
        fullName
      }
      quantifiable_task {
        id
        name
        quantity
        unit
      }
    }
  }
`;
const ApproveWorkPermit = (props) => {
  const { refetch, permitId } = props;
  const [workPermit, setWorkPermit] = useState(null);
  const { data, loading: getWorkPermitLoading } = useQuery(GET_WORK_PERMIT, {
    variables: { id: permitId },
    fetchPolicy: "network-only",
  });

  const [updateWorkPermit, { loading: isWorkPermitubmitting }] =
    useMutation(UPDATE_WORK_PERMIT);

  useEffect(() => {
    if (data && !getWorkPermitLoading) {
      setWorkPermit(data.projects_work_permits_by_pk);
    }
  }, [data]);

  const submitHandler = async (status) => {
    try {
      const response = await updateWorkPermit({
        variables: {
          object: {
            id: permitId,
            status,
          },
        },
      });
      if (response?.data?.update_work_permit?.status) {
        toast.success(
          `Work permit ${status ? "approved" : "declined"} successfully`
        );
      }
      hideModal();
      refetch();
    } catch (e) {
      console.log(e);
    }
  };
  const hideModal = () => {
    props.hideModal();
  };
  if (props.isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[400px]"
          isShown={true}
          hideModal={hideModal}
          modalTitle="Approve Work Permit"
          modalBottom={
            <div className="flex justify-between mx-4">
              <Button className="mr-3" type="light" onClick={hideModal}>
                Cancel
              </Button>
              <div>
                {workPermit?.status === "PENDING" && (
                  <Button
                    className="bg-red-500 disabled:bg-red-300 hover:bg-red-400 mr-2"
                    disabled={isWorkPermitubmitting}
                    // isLoading={isWorkPermitubmitting}
                    onClick={() => {
                      submitHandler(false);
                    }}
                    type="submit">
                    Decline
                  </Button>
                )}
                {workPermit?.status !== "APPROVED" && (
                  <Button
                    disabled={isWorkPermitubmitting}
                    // isLoading={isWorkPermitubmitting}
                    onClick={() => {
                      submitHandler(true);
                    }}
                    type="submit">
                    Approve
                  </Button>
                )}
              </div>
            </div>
          }>
          {getWorkPermitLoading ? (
            <div className="flex text-dark-gray justify-center items-center mt-16">
              Loading....
            </div>
          ) : (
            workPermit && (
              <div className="h-[85%] mt-4 flex flex-col justify-between">
                <div>
                  <h2 className="text-sm font-medium mb-2 text-dark-gray">
                    Task description
                  </h2>
                  <p className="">
                    <span className="font-semibold mr-2">
                      {workPermit.quantifiable_task.quantity}{" "}
                      {workPermit.quantifiable_task.unit}
                    </span>
                    <span className="">
                      {workPermit.quantifiable_task.name}
                    </span>
                  </p>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span
                    className={`${workPermit.status.toLowerCase()} flex items-center`}>
                    <DotIcon />
                    {capitalizeFirstLetter(workPermit.status)}
                  </span>
                  <span className="text-dark-gray">
                    Requested by {workPermit.created_by_user.fullName}
                  </span>
                </div>
              </div>
            )
          )}
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default ApproveWorkPermit;
