import { gql, useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  ArrowLeftShort,
  Calendar3,
  Check2,
  PlusLg,
} from "react-bootstrap-icons";
import Active from "../../components/Report/Active";
import Inactive from "../../components/Report/Inactive";
// import Badge from "../../components/UI/Badge";
import Button from "../../components/UI/Button";
import Card from "../../components/UI/Card";
import Dropdown from "../../components/UI/Dropdown";
import IconButton from "../../components/UI/IconButton";
import Radio from "../../components/UI/Radio";
import Textarea from "../../components/UI/Textarea";
import useInput from "../../hooks/use-input";
import { useProject } from "../../store/project-context";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../../components/UI/Toast";
import Complete from "../../components/Report/Complete";
import DatePicker from "../../components/UI/DatePicker";
import NoProject from "../../components/UI/NoProject";

const GET_MILESTONES = gql`
  query MyQuery($projectId: uuid!) {
    projects_milestones(
      order_by: { created_at: desc }
      where: { projectId: { _eq: $projectId } }
    ) {
      id
      endDate
      name
      startDate
      projectId
      milestone_equipments(
        where: { type: { _neq: "OWNED" }, is_purchased: { _eq: false } }
      ) {
        id
        quantity
        unitPrice
        noOfHours
        type
        equipment {
          id
          name
          status
          unit
        }
      }
      milestone_materials {
        id
        quantity
        unitPrice
        material {
          id
          name
          unit
          storages(
            where: {
              status: { _eq: "INCOMING" }
              projectId: { _eq: $projectId }
            }
            order_by: { created_at: asc }
          ) {
            id
            unitPrice
            quantity
          }
          incoming: storages_aggregate(
            where: {
              status: { _eq: "INCOMING" }
              projectId: { _eq: $projectId }
            }
          ) {
            aggregate {
              sum {
                quantity
              }
            }
          }
          # outgoing: storages_aggregate(where: { status: { _eq: "OUTGOING" } }) {
          #   aggregate {
          #     sum {
          #       quantity
          #     }
          #   }
          # }
        }
      }
      milestone_labors {
        id
        quantity
        unitPrice
        noOfHours
        labor {
          id
          type
        }
      }
      checklists {
        #(where: { isChecked: { _eq: false } })
        id
        title
        isChecked
      }
      quantifiable_tasks {
        name
        quantity
        unit
        unitRate
        id
      }
      tests(where: { isChecked: { _eq: false } }) {
        name
        id
        isChecked
      }
      reports {
        checklist
        equipments
        materials
        tasks
        labor
      }
    }
  }
`;
const INSERT_REPORT = gql`
  mutation MyMutation($object: projects_report_insert_input!) {
    insert_projects_report_one(object: $object) {
      id
    }
  }
`;

const ReportPage = () => {
  const [isActive, setIsActive] = useState(true);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [reason, setReason] = useState(null);
  const [selectedMilestones, setSelectedMilestones] = useState([]);
  // const [milestone, setMilestone] = useState(null);
  const [milestones, setMilestones] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [date, setDate] = useState(new Date());
  const { currentProject } = useProject();
  const navigate = useNavigate();
  const {
    data: milestonesData,
    loading: getMilestonesLoading,
    error: getMilestonesError,
    refetch: refetchMilestones,
  } = useQuery(GET_MILESTONES, {
    variables: { projectId: currentProject?.id },
  });

  useEffect(() => {
    // iniialize quantities to 0;
    let allTasksCompleted = true;
    let allChecklistsChecked = true;

    const milestonesFiltered = milestonesData?.projects_milestones?.map(
      (milestone) => {
        console.log(milestone.milestone_materials);

        const materials = milestone.milestone_materials.map((item) => {
          const incomingQuantity =
            item.material.incoming.aggregate.sum.quantity || 0;
          return {
            ...item,
            quantity: 0,
            unitPrice: item.material.storages.length
              ? item.material.storages[0].unitPrice
              : item.material.unitPrice,
            availableAmount: incomingQuantity,
            // material: {
            //   ...item.material,
            //   storages: storages.length > 0 ? storages : item.material.storages,
            // },
          };
        });
        console.log(materials);
        milestone.checklists?.map((ch) => {
          if (!ch.isChecked) {
            allChecklistsChecked = false;
            return;
          }
        });
        const quantifiable_tasks = milestone.quantifiable_tasks.map((item) => {
          let completedQuantity = 0;
          milestone.reports?.map((rep) => {
            if (!rep.inactive) {
              JSON.parse(rep.tasks).map((report_item) => {
                if (report_item.id === item.id)
                  completedQuantity += report_item.quantity;
              });
            }
          });
          const remainingQuantity =
            item.quantity - completedQuantity > 0
              ? item.quantity - completedQuantity
              : 0;
          const completedPercentage = (
            ((item.quantity - remainingQuantity) / item.quantity) *
            100
          ).toFixed(2);
          if (completedPercentage < 100) {
            allTasksCompleted = false;
          }
          return {
            ...item,
            quantity: 0,
            requiredQuantity: item.quantity,
            completedQuantity,
            remainingQuantity,
            completedPercentage,
          };
        });

        const equipments = milestone.milestone_equipments.map((item) => {
          return {
            ...item,
            // quantity: 0,
            requiredQuantity: item.quantity,
          };
        });
        const labors = milestone.milestone_labors.map((item) => {
          return {
            ...item,
            // quantity: 0,
            requiredQuantity: item.quantity,
          };
        });
        console.log(materials);
        return {
          id: milestone.id,
          name: milestone.name,
          quantifiable_tasks,
          materials,
          equipments,
          labors,
          checklists: milestone.checklists,
          tests: milestone.tests,
          submitted_at: date,
          allTasksCompleted,
          allChecklistsChecked,
        };
      }
    );
    console.log(milestonesFiltered);
    if (milestonesFiltered) setMilestones(milestonesFiltered);
  }, [milestonesData]);
  const {
    value: notes,
    isValid: notesIsValid,
    isInvalid: notesIsInValid,
    inputChangeHandler: notesChangeHandler,
    inputBlurHandler: notesBlurHandler,
  } = useInput((value) => value.trim() !== "");
  const formIsValid = notesIsValid && reason !== null;
  const [
    insertReport,
    { loading: isReportSubmitting, error: insertReportError },
  ] = useMutation(INSERT_REPORT);

  useEffect(() => {
    refetchMilestones();
    setSelectedMilestone(null);
    setSelectedMilestones([]);
  }, [currentProject?.id, refetchMilestones]);
  const isActiveChange = () => {
    setIsActive(!isActive);
  };
  const milestoneChangeHandler = (item) => {
    console.log(item);
    if (selectedMilestones.indexOf(item) !== -1) {
      return;
    }
    setSelectedMilestone(item);
    setSelectedMilestones([item, ...selectedMilestones]);
    let doesExist = data.find((el) => el.id === item?.id);
    console.log(doesExist);
    if (!doesExist) {
      setData(() => [item, ...data]);
    }
  };
  const selectMilestone = (milestone) => {
    if (milestone.id === selectedMilestone?.id) {
      setSelectedMilestone(null);
      console.log(milestone);
      return;
    }
    setSelectedMilestone(data.find((el) => el.id === milestone.id));
  };
  const dateChangeHandler = (date) => {
    const now = new Date();
    date.setHours(now.getHours());
    date.setMinutes(now.getMinutes());
    setDate(date);
  };
  const submitHandler = async () => {
    try {
      if (currentProject.id) {
        const response = await insertReport({
          variables: {
            object: {
              projectId: currentProject.id,
              inactive: true,
              reason,
              notes,
              submitted_at: date,
            },
          },
        });
        if (!insertReportError && response.data.insert_projects_report_one.id) {
          navigate("/report", {
            state: { success: "Report marked as inactive" },
          });
        }
      } else {
        if (!currentProject.id) {
          setError("Current project not found");
        }
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
  };
  if (currentProject) {
    return (
      <div className="m-3 ">
        <h1 className="font-semibold flex items-center">
          <Link to="/report">
            <IconButton
              className={`bg-gray-100 !p-1 focus:!ring-0 border-none mr-3`}
              type="rounded"
            >
              <ArrowLeftShort className="text-2xl " />
            </IconButton>
          </Link>
          Add Report
        </h1>
        <div className="grid grid-cols-12 gap-4 text-sm">
          <div className="flex flex-col col-span-4">
            <Card className="mt-2 shadow-sm">
              <div className="">
                <h3 className="font-medium">Date</h3>
                <DatePicker
                  value={date}
                  bgColor="bg-gray-100"
                  className="w-36"
                  icon={<Calendar3 />}
                  minDate={new Date(currentProject.startDate)}
                  maxDate={new Date(currentProject.endDate)}
                  onChange={dateChangeHandler}
                  dateFormat="dd/MM/yyyy"
                  id="start-date"
                  placeholderText={moment().format("L")}
                />
              </div>
              <div className="flex space-x-4 mt-4">
                <Radio
                  checked={isActive}
                  id="active"
                  label="Active"
                  onChange={isActiveChange}
                />
                <Radio
                  id="inactive"
                  label="Inactive"
                  onChange={isActiveChange}
                />
              </div>
            </Card>
            {isActive ? (
              <>
                <Card className="mt-2 shadow-sm">
                  <h3 className="font-medium">Phase</h3>
                  <div className="flex justify-between items-center mt-2">
                    <Dropdown
                      className="w-full"
                      selectHandler={milestoneChangeHandler}
                      // onBlur={phaseBlurHandler}
                      // value={milestone?.name}
                      error={getMilestonesError}
                      // label="Phase"
                      // helperText={<>Milestone is required</>}
                      loading={getMilestonesLoading}
                      errorMessage={getMilestonesError?.message}
                      items={milestones}
                      propertyName="name"
                    />
                    {/* <IconButton
                      disabled={!milestone}
                      onClick={addMilestone}
                      className="group ml-2 w-9 h-9 bg-secondary/20 disabled:hover:bg-secondary/10 disabled:bg-secondary/10"
                      type="rounded-filled"
                    >
                      <PlusLg className="text-primary w-5 h-5 text-lg group-disabled:text-primary/50" />
                    </IconButton> */}
                  </div>
                  <div className="flex flex-col mt-4">
                    {selectedMilestones.map((item, index) => (
                      <span key={index}>
                        <button
                          onClick={() => selectMilestone(item)}
                          className={`p-2 w-full rounded-lg my-1 ${
                            selectedMilestone?.id === item.id
                              ? "bg-secondary/20 hover:bg-secondary/30"
                              : "hover:bg-gray-100"
                          }`}
                        >
                          <span className="flex justify-between items-center">
                            {item.name}
                            {selectedMilestone?.id === item.id && (
                              <Check2 className="mx-2" />
                            )}
                          </span>
                        </button>
                        {index !== selectedMilestones.length - 1 && <hr />}
                      </span>
                    ))}
                  </div>
                </Card>
                <Card className="mt-2 shadow-sm">
                  <h3 className="font-medium">Notes</h3>
                  <Textarea
                    onChange={notesChangeHandler}
                    value={notes}
                    placeholder="Description of today’s work..."
                  />
                </Card>
              </>
            ) : (
              <>
                <Card className="mt-2 shadow-sm">
                  <h3 className="font-medium">Reason for inactivity</h3>
                  <div className="flex justify-between items-center mt-2">
                    <Dropdown
                      className="!w-full"
                      selectHandler={(e) => setReason(e)}
                      value={reason}
                      items={[
                        "Bad weather",
                        "Equipment breakdowns",
                        "Lack of materials",
                        "Worker availability issues",
                        "Unexpected site conditions",
                      ]}
                      // propertyName="name"
                    />
                  </div>
                  <h3 className="font-medium mt-4">Notes</h3>
                  <Textarea
                    onChange={notesChangeHandler}
                    onBlur={notesBlurHandler}
                    error={notesIsInValid}
                    value={notes}
                    placeholder="Explain today’s situation..."
                  />
                  <div className="flex justify-end mt-4">
                    <Button
                      onClick={() => {
                        navigate("/report");
                      }}
                      className="mr-3"
                      type="light"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!formIsValid}
                      isLoading={isReportSubmitting}
                      onClick={submitHandler}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </Card>
              </>
            )}
          </div>
          {isActive ? (
            selectedMilestone &&
            selectedMilestone.allChecklistsChecked &&
            selectedMilestone.allTasksCompleted ? (
              <Complete
                selectedMilestone={selectedMilestone}
                tests={selectedMilestone?.tests || []}
              />
            ) : (
              <Active
                data={data}
                setData={setData}
                date={date}
                notes={notes}
                selectedMilestone={selectedMilestone}
                setSelectedMilestone={setSelectedMilestone}
              />
            )
          ) : (
            <Inactive date={date} />
          )}
        </div>
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default ReportPage;
