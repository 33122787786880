import React, { useEffect, useState } from "react";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../functions/extra";
import { DotIcon, Pencil, PencilIcon } from "lucide-react";
import { PencilFill } from "react-bootstrap-icons";

const UPDATE_MATERIAL_PERMIT = gql`
  mutation MyMutation($object: UpdateMaterialPermitInput!) {
    update_material_permit(object: $object) {
      status
    }
  }
`;
const GET_MATERIAL_PERMITS = gql`
  query MyQuery($task_id: uuid!) {
    projects_material_permits(where: { task_id: { _eq: $task_id } }) {
      id
      status
      quantity
      material {
        name
        unit
      }
      quantifiable_task {
        id
        name
        quantity
        unit
      }
      created_by_user {
        fullName
      }
    }
  }
`;
const ApproveMaterialPermits = (props) => {
  const { refetch, taskId } = props;
  const [materialPermits, setMaterialPermits] = useState([]);
  const { data, loading: getMaterialPermitsLoading } = useQuery(
    GET_MATERIAL_PERMITS,
    {
      variables: { task_id: taskId },
      fetchPolicy: "network-only",
    }
  );

  const [updateMaterialPermits, { loading: isMaterialPermitsubmitting }] =
    useMutation(UPDATE_MATERIAL_PERMIT);

  useEffect(() => {
    if (data && !getMaterialPermitsLoading) {
      setMaterialPermits(data.projects_material_permits);
    }
  }, [data]);

  const submitHandler = async (status) => {
    try {
      const response = await updateMaterialPermits({
        variables: {
          object: {
            taskId,
            status,
          },
        },
      });
      if (response?.data?.update_material_permit?.status) {
        toast.success(
          `Material permit ${status ? "approved" : "declined"} successfully`
        );
      }
      hideModal();
      refetch();
    } catch (e) {
      console.log(e);
    }
  };
  const hideModal = () => {
    props.hideModal();
  };
  if (props.isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[500px]"
          isShown={true}
          hideModal={hideModal}
          modalTitle="Approve Material Permit"
          modalBottom={
            <div className="flex justify-between mx-4">
              <Button className="mr-3" type="light" onClick={hideModal}>
                Cancel
              </Button>
              <div>
                {materialPermits[0]?.status === "PENDING" && (
                  <Button
                    className="bg-red-500 disabled:bg-red-300 hover:bg-red-400 mr-2"
                    disabled={isMaterialPermitsubmitting}
                    // isLoading={isMaterialPermitsubmitting}
                    onClick={() => {
                      submitHandler(false);
                    }}
                    type="submit">
                    Decline
                  </Button>
                )}
                {materialPermits[0]?.status !== "APPROVED" && (
                  <Button
                    disabled={isMaterialPermitsubmitting}
                    // isLoading={isMaterialPermitsubmitting}
                    onClick={() => {
                      submitHandler(true);
                    }}
                    type="submit">
                    Approve
                  </Button>
                )}
              </div>
            </div>
          }>
          {getMaterialPermitsLoading ? (
            <div className="flex text-dark-gray justify-center items-center mt-16">
              Loading....
            </div>
          ) : (
            materialPermits.length && (
              <div className="h-[85%] mt-4 flex flex-col justify-between">
                <div>
                  <h2 className="text-sm font-medium mb-2 text-dark-gray">
                    Task description
                  </h2>
                  <p className="">
                    <span className="font-semibold mr-2">
                      {materialPermits[0].quantifiable_task.quantity}{" "}
                      {materialPermits[0].quantifiable_task.unit}
                    </span>
                    <span className="">
                      {materialPermits[0].quantifiable_task.name}
                    </span>
                  </p>
                  <h2 className="text-sm mt-5 font-medium mb-2 text-dark-gray">
                    Materials
                  </h2>
                  <ul className="ml-5 space-y-2 list-disc">
                    {materialPermits.map((permit, index) => (
                      <li key={index}>
                        <p className="flex justify-between items-center">
                          <span className="font-semibold mr-2">
                            {permit.material.name}
                          </span>
                          <span className="flex items-center gap-2">
                            <span className="text-sm">
                              X {permit.quantity} {permit.material.unit}
                            </span>
                            <PencilFill onClick={() => {alert("In progress")}} />
                          </span>
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span
                    className={`${materialPermits[0].status.toLowerCase()} flex items-center`}>
                    <DotIcon />
                    {capitalizeFirstLetter(materialPermits[0].status)}
                  </span>
                  <span className="text-dark-gray">
                    Requested by {materialPermits[0].created_by_user.fullName}
                  </span>
                </div>
              </div>
            )
          )}
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default ApproveMaterialPermits;
