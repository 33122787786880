import React from "react";
// import { Card, CardContent } from "@/components/ui/card";
import useInput from "../hooks/use-input";
import Button from "../components/UI/Button";
import Input from "../components/UI/Input";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const FORGET_PASSWORD = gql`
  mutation MyMutation2($object: ForgotPasswordInput!) {
    forgot_password(object: $object) {
      status
    }
  }
`;

const ForgotPasswordPage = () => {
  const {
    value: email,
    isValid: emailIsValid,
    isInvalid: emailIsInValid,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(
    (value) => value.trim() !== "" && value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  );
  const navigator = useNavigate();
  const [forgetPassword, { loading }] = useMutation(FORGET_PASSWORD);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (emailIsValid) {
        const response = await forgetPassword({
          variables: { object: { email } },
          context: {
            headers: {
              "x-hasura-role": "anonymous",
            },
          },
        });
        if (response?.data?.forgot_password?.status) {
          navigator("/login")
          toast.success("A new password has been sent to your email");
        }
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.message);
    }
  };

  return (
    <div
      className="grid grid-cols-12 h-screen justify-center items-center"
      style={{
        //   backgroundImage: `url(${bg})`,
        backgroundPositionX: "right",
        backgroundPositionY: "bottom",
        backgroundRepeat: "no-repeat",
      }}>
      <div className="border rounded-lg p-6 col-start-5 col-span-4">
        <form onSubmit={handleSubmit} className="flex flex-col gap-8">
          <div>
            <h1 className="text-xl mb-4 font-bold">Forgot password</h1>
            <Input
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
              value={email}
              error={emailIsInValid}
              icon={<MailOutlineIcon className="!text-lg" />}
              // success={success}
              className="mb-3"
              id="email"
              label="Email"
              helperText={<>A valid email is required</>}
              placeholder="youremail@gmail.com"
              required
              type="email"
            />
          </div>
          <Button
            disabled={!emailIsValid}
            type="submit"
            isLoading={loading}
            onClick={handleSubmit}
            className="w-full">
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
